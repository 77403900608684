import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'reactstrap';
import style from './PasswordReset.styles.css';
import { useSelector, useDispatch } from 'react-redux';
import { componentKey } from './PasswordResetSlice';
import CommonJs from '../../../assets/js/common.js';
import queryString from 'query-string';
import validator from 'validator';
import axios from 'axios';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

export default function PasswordReset({}) {

    var query_param = document.location.href.replace("/#/", "/").split("?");
    if(query_param.length > 1){
        query_param = query_param[1];
    }else{
        query_param = query_param[0];
    }

    let params = queryString.parse(query_param)
    var Email = (typeof params.Email !== 'undefined') ? params.Email : "";
    var Secret = (typeof params.Secret !== 'undefined') ? params.Secret : "";

    const [isInviteValid, setIsInviteValid] = useState(null);
    const [inviteValidMessage, setInviteValidMessage] = useState("");

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const DefaultButtonText = 'Submit';
    const [buttonText, setButtonText] = useState(DefaultButtonText);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');
    const[isBtnDisabled, setBtnDisabled] = useState(false);
    const dispatch = useDispatch();
    useEffect(()  => {
        if(isInviteValid == null){
            axios
            .post(process.env.API_URL + "/api/v1/developer/validateresetpassword", {"Email": Email, "Secret": Secret}).then((res) => {
                setIsInviteValid(res.data.status);
                setInviteValidMessage(res.data.message);
            });
        }
        
        document.body.classList.add(style.bodyclass);
    
        return () => {
            document.body.classList.remove(style.bodyclass);
        };
    });
    const { randomString } = useSelector((state) => state[componentKey]);

    const validatePassword = (value) => {
        setPassword(value);
        if (validator.isStrongPassword(value, {
        minLength: 8, minLowercase: 1,
        minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setPasswordErrorMessage('');
            return true;
        }
        setPasswordErrorMessage('Your password must have 8 or more characters, upper and lowercase letters, at least one number, and at least one special character.');
        return false;
    }

    const validateConfirmPassword = (value) => {
        setConfirmPassword(value);
        if (value != "" && value == password && validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
            })) {
            setConfirmPasswordErrorMessage('');
            return true;
        }
        setConfirmPasswordErrorMessage('The Password and Confirmation Password must match and should not be empty.');
        return false;
    }

    const validateForm = (e) =>{
        e.preventDefault();
        var status = validatePassword(password);
        status = (validateConfirmPassword(confirmPassword) && status);
        if(status){
            setButtonText('Submiting...');
            setBtnDisabled(true);
            axios
            .post(process.env.API_URL + "/api/v1/developer/resetpassword", {
                Email: Email,
                Password: password,
                ConfirmPassword: confirmPassword,
                Secret: Secret
            }).then((res) => {
                setButtonText(DefaultButtonText);
                setBtnDisabled(false);
                if(res.data.status){
                    setPassword('');
                    setConfirmPassword('');
                    alertify.alert(res.data.message).setHeader('<em>Tax1099</em>');
                    document.location.href = "/#/";
                }else{
                    alertify.alert(res.data.message).setHeader('<em>Tax1099</em>');
                }
                
            })
            .catch((error) => {
                setButtonText(DefaultButtonText);
                setBtnDisabled(false);
                alertify.alert(error.response.data.message).setHeader('<em>Tax1099</em>');
            });
        }
    }

    
    return (
        <Container className={style.mainContainer} fluid>
            <div className="col-md-6 offset-md-3">
                <link href="https://www.tax1099.com/Content/Modern/css/Login.css" rel="stylesheet" />
                <div className="panel panel-login" style={{marginBottom: "20px", backgroundColor: "#fff", border: "1px solid transparent"}}>
                    <div className="panel-heading">
                        <div className="col-md-12 justify-content-md-center">
                            <div className="logo logo-center col-md-12">
                                <div className="logo two">
                                    <a id="logoURL" href="https://www.tax1099.com/">
                                        <img id="logoimgUrl" src="https://www.tax1099.com/Content/NewTemplate/Images/Tax1099.svg" alt="Tax1099_Logo" style={{height: "80px", width: "185px"}} />
                                    </a>
                                </div>                                    
                            </div>
                            <h1 className="col-md-12" style={{fontStyle: "italic", padding: "0", fontSize: "28px"}}>Set New Password</h1>                            
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row" style={{justifyContent: "center"}}>
                            {
                                (isInviteValid === null) ? <> <i className={["fa fa-spinner fa-spin", style.loadingSpinner].join(' ')} style={{lineHeight: "unset"}}></i><span className={style.customFontSizeSmall}> Loading...</span></>
                                :
                                (isInviteValid === 1) ?
                                    <div className="col-md-12">
                                        <div className="input-layout">
                                            <input className="cleanup validate-empty " autoFocus id="txtEmail" type="email" defaultValue={Email} autoComplete="off" readOnly={true} style={{ width: "100%", color: "#555", fontWeight:"700", fontSize:"16" }} />
                                        </div>
                                        <div className="input-layout">
                                            <input className="cleanup validate-empty " autoFocus id="txtPassword" type="password" style={{width:"100%"}} autoComplete="off" placeholder="Password" onChange={(e) => validatePassword(e.target.value)} defaultValue={password} value={password} />
                                            <div className="clearfix"></div>
                                            <span className="login_signup_error" style={{color:"red"}}>{passwordErrorMessage}</span>
                                        </div>
                                        <div className="input-layout">
                                            <input className="cleanup validate-empty " autoFocus id="txtConfirmPassword" type="password" style={{width:"100%"}} autoComplete="off" placeholder="Confirm Password" onChange={(e) => validateConfirmPassword(e.target.value)} defaultValue={confirmPassword} value={confirmPassword} />
                                            <div className="clearfix"></div>
                                            <span className="login_signup_error" style={{color:"red"}}>{confirmPasswordErrorMessage}</span>
                                        </div>
                                        <div className="form-group" style={{marginTop:"20px"}}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <button type="button" className="form-control btn btn-default" id="btnPSW" onClick={(e) => validateForm(e)} disabled={isBtnDisabled} >{buttonText}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                <div id="divSetPassword" runat="server"><label style={{color: "#135db1"}}><b>{inviteValidMessage}</b></label></div>
                            }
                            
                            <div className="col-md-12">
                                <div className="text-center"> back to <a href="/#/" style={{color:"#c53938", fontWeight:"bold"}}>Login!</a> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="col-md-6 offset-md-3">
                    <h5 style={{color:"#fff",textAlign: "center"}}> © 2020-2021 Tax1099.com. All Rights Reserved <br/>
                        <a style={{color:"#fff"}} href="https://www.tax1099.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy </a>| <a style={{color:"#fff"}} href="https://www.tax1099.com/saas-terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                    </h5>
                </div>
            </div>
            <script src={CommonJs} ></script>
        </Container>
    );

}