import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'reactstrap';
import style from './ForgotPassword.styles.css';
import { useSelector, useDispatch } from 'react-redux';
import { componentKey } from './ForgotPasswordSlice';
import CommonJs from '../../../assets/js/common.js';
import axios from 'axios';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

export default function ForgotPassword({}) {

    const DefaultButtonText = 'Request Reset Link';
    const [email, setEmail] = useState('');
    const [buttonText, setButtonText] = useState(DefaultButtonText);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [formSubmissionErrorMessage , setFormSubmissionErrorMessage] = useState('');
    const[isBtnDisabled, setBtnDisabled] = useState(false);
    const dispatch = useDispatch();
    useEffect(()  => {
        document.body.classList.add(style.bodyclass);
    
        return () => {
            document.body.classList.remove(style.bodyclass);
        };
    });
    const { randomString } = useSelector((state) => state[componentKey])

    const validateEmail = (value) => {
        setEmail(value);
        if($.trim(value) == ""){
            setEmailErrorMessage('Email is required');
            return false;
        }else if(!isValidEmail(value)){
            setEmailErrorMessage('Please enter valid email');
            return false;
        }
        setEmailErrorMessage('');
        return true;
    }

    const isValidEmail = (value) => {
        return String(value)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
    const validateForm = (e) =>{
        e.preventDefault();
        var valid = true;
        if($.trim(email) == ""){
            setEmailErrorMessage('Email is required');
            valid = false;
        }else if(!isValidEmail(email)){
            setEmailErrorMessage('Please enter valid email');
            valid = false;
        }
        if(valid){
            setButtonText('Submiting...');
            setBtnDisabled(true);
            setEmailErrorMessage('');
            axios
            .post(process.env.API_URL + "/api/v1/developer/requestresetpassword", {
                Email: email,
            }).then((res) => {
                setEmail('');
                setButtonText(DefaultButtonText);
                setBtnDisabled(false);
                alertify.alert(res.data.message).setHeader('<em>Tax1099</em>');
            })
            .catch((error) => {
                setButtonText(DefaultButtonText);
                setBtnDisabled(false);
                alertify.alert(error.response.data.message).setHeader('<em>Tax1099</em>');
            });
        }
    }

    
    return (
        <Container className={style.mainContainer} fluid>
            <div className="col-md-6 offset-md-3">
                <link href="https://prod-cdn.tax1099.com/Content/Modern/css/Login.css" rel="stylesheet" />
                <div className="panel panel-login" style={{marginBottom: "20px", backgroundColor: "#fff", border: "1px solid transparent"}}>
                    <div className="panel-heading">
                        <div className="col-md-12 justify-content-md-center">
                            <div className="logo logo-center col-md-12">
                                <div className="logo two">
                                    <a id="logoURL" href="https://www.tax1099.com/">
                                        <img id="logoimgUrl" src="https://prod-cdn.tax1099.com/Content/NewTemplate/Images/Tax1099.svg" alt="Tax1099_Logo" style={{height: "80px", width: "185px"}} />
                                    </a>
                                </div>                                    
                            </div>
                            <h1 className="col-md-12" style={{fontStyle: "italic", padding: "0", fontSize: "28px"}}>Forgot Password</h1>                            
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="input-layout">
                                    <input className="cleanup validate-empty " autoFocus id="txtEmail" name="Email" type="email" placeholder="E-Mail" style={{width:"100%"}} autoComplete="off" onChange={(e) => validateEmail(e.target.value)} defaultValue={email} value={email} />
                                    <div className="clearfix"></div>
                                    <span className="login_signup_error" id="lblValidation" style={{color:"red"}}>{emailErrorMessage}</span>
                                </div>
                                <div className="form-group" style={{marginTop:"20px"}}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <button type="button" className="form-control btn btn-default" id="btnPSW" onClick={(e) => validateForm(e)} disabled={isBtnDisabled} >{buttonText}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center"> back to <a href="/#/" style={{color:"#c53938", fontWeight:"bold"}}>Login!</a> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="col-md-6 offset-md-3">
                    <h5 style={{color:"#fff",textAlign: "center"}}> © 2020-2021 Tax1099.com. All Rights Reserved <br/>
                        <a style={{color:"#fff"}} href="https://www.tax1099.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy </a>| <a style={{color:"#fff"}} href="https://www.tax1099.com/saas-terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                    </h5>
                </div>
            </div>
            <script src={CommonJs} ></script>
        </Container>
    );

}