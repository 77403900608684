document.addEventListener("DOMContentLoaded", () => {
	var navbar = $('.navContainer');
	$(window).scroll(function () {
		if ($(window).scrollTop() <= 40) {
			navbar.css('box-shadow', 'none');
			$('.section-border').css('padding', '9.5% 0% 0% 5%');
		} else {
			navbar.css('box-shadow', '0 10px 10px rgba(0,0,0,.1)');
			$('.section-border').css('padding', '14.5% 0% 0% 5%');
		}
	});
});