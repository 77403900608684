import React from 'react';
import {
	HashRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import PasswordResetPage from './businesslogic/passwordreset/PasswordReset';
import ForgotPasswordPage from './businesslogic/forgotpassword/ForgotPassword';
import AcceptInvitationPage from './businesslogic/acceptinvitation/AcceptInvitation';
import Header from './businesslogic/dependencies/Header';
import LeftPage from './businesslogic/New Login/LeftPage';
import RightPage from './businesslogic/New Login/RightPage';
import style from './businesslogic/New Login/LoginPage.styles.css';
import './businesslogic/New Login/LoginPage.styles.css';

function Home() {

	return (
		<React.Suspense fallback="Loading...">
			<Router>
				<Switch>
					<Route exact path="/forgot-password">
						<>
							<ForgotPasswordPage />
						</>
					</Route>
					<Route exact path="/password-reset">
						<>
							<PasswordResetPage />
						</>
					</Route>
					<Route exact path="/">
						<>
							<div className={["col-sm-12 col-12 plr-0 bg-div container-fluid", style.plr0, style.bgDiv].join(' ')} style={{height: 'auto'}}>
								<div className={["row maindiv",style.maindiv].join(' ')} style={{width:'auto'}}>
								<LeftPage />
								<RightPage />
								</div>
							</div>
						</>
					</Route>
					<Route exact path="/accept-invitation">
						<>
							<Header />
							<AcceptInvitationPage />
						</>
					</Route>
				</Switch>
			</Router>
		</React.Suspense>
	)

}

export default Home;