import React, { useEffect, useState } from "react";
import style from './LoginPage.styles.css';
import img1 from './img/sign-logowht.png';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

export default function RightPage({ }) {
   const [email, setEmail] = useState('');
   const [emailErrorMessage, setEmailErrorMessage] = useState('')
   const [password, setPassword] = useState('');
   const [passwordErrorMessage, setPasswordErrorMessage] = useState('')
   const [formSubmissionErrorMessage, setFormSubmissionErrorMessage] = useState('');
   const [loginBtnText, setLoginBtnText] = useState('LOGIN');
   const [isLoginBtnDisabled, setLoginBtnDisabled] = useState(false);

   const validateEmail = (value) => {
      setEmail(value);
      if (value != "") {
         setEmailErrorMessage('');
         return true;
      }
      setEmailErrorMessage('Email is required');
      return false;
   }

   const validatePassword = (value) => {
      setPassword(value);
      if (value != "") {
         setPasswordErrorMessage('');
         return true;
      }
      setPasswordErrorMessage('Password is required');
      return false;
   }


   const validateForm = (e) => {
      e.preventDefault();
      var valid = true;
      if ($.trim(email) == "") {
         setEmailErrorMessage('Email is required');
         valid = false;
      }
      if ($.trim(password) == "") {
         setPasswordErrorMessage('Password is required');
         valid = false;
      }
      if (valid) {
         console.log(process.env.API_URL);
         setLoginBtnText('Loading...');
         setLoginBtnDisabled(true);
         setFormSubmissionErrorMessage('');
         axios
            .post(process.env.API_URL + "/api/v1/login/thirdparty", {
               login: email,
               password: password,
            }).then((res) => {
               if (typeof res.data.redirectURL !== 'undefined') {
                  setLoginBtnText('Redirecting...');
                  document.location = res.data.redirectURL;
               } else {
                  setLoginBtnText('LOGIN');
                  setLoginBtnDisabled(false);
                  setFormSubmissionErrorMessage(res.data.message);
               }
            })
            .catch((error) => {
               setLoginBtnText('LOGIN');
               setLoginBtnDisabled(false);
               setFormSubmissionErrorMessage(error.response.data.message);
            });
      }
   }

   return (
         <div className={["col-sm-6 col-12 plr-0", style.plr0].join(' ')}>
            <div className={["col-sm-12 logo-space  logo-space-xs", style.logoSpace, style.logoSpaceXs]} style={{ visibility: "hidden" }}><img src={img1} /></div>

            <div className={["col-sm-12 col-xs-12  frxlg pl-0 divwrap", style.frxlg, style.pl0, style.divWrap].join(' ')}>
               <form method="post" onSubmit={(e) => validateForm(e)}>
                  <input name="__RequestVerificationToken" type="hidden" defaultValue="wz8IraGGiVgnlv21W47P3i_YRaBSVpt3byud-W-JMzMmzC9w-WYcoqhKZSsQCnPKcLYuNKMIRwmIiqDkUMr9zH2Ml2PgKxBc9WNFvUbwvtY1" />
                  <div className="col-sm-10  col-xs-12 col-sm-offset-1">

                     <div className={["col-sm-10    col-xs-12 plr-0", style.plr0].join(' ')}>
                        <p className={["title03 ", style.title03].join(' ')}>Login With E-Mail</p>
                        <p className={[" txt-que mb-6", style.txtQue, style.mb6].join(' ')}>
                           <a href="sc02.html"><span className={["blu", style.blu].join(' ')} style={{ textDecoration: "underline" }}> </span>
                           </a>
                        </p>
                        <div className={["col-sm-12 col-xs-12  plr-0 mt-10 space-tb", style.plr0, style.mt10, style.spaceTb].join(' ')}>
                           <div className={["col-sm-12 col-xs-12  pl-0  form-group  label-group1", style.pl0, style.formGroup, style.labelGroup1].join(' ')}>

                              <label htmlFor="email" className={["lblcss", style.lblcss].join(' ')} >E-Mail Address *</label>
                              <input type="text" className={["form-control txtbox", style.formControl, style.txtbox].join(' ')} placeholder="Enter E-Mail Address" required="" defaultValue="" onChange={(e) => validateEmail(e.target.value)} autoComplete="off" />
                              <div className="clearfix"></div>
                              <span className={style.loginSignupError} >
                                 <span className={["field-validation-valid", style.customFontSizeSmall].join(' ')} style={{ fontSize: "15px" }}>{emailErrorMessage}</span>
                              </span>
                           </div>


                           <div className={["col-sm-12 col-xs-12 pl-0 form-group floating-label-group", style.pl0, style.formGroup, style.floatingLabelGroup].join(' ')}>
                              <label className={["lblcss", style.lblcss].join(' ')}>Password * </label>
                              <input type="password" className={["form-control txtbox", style.formControl, style.txtbox].join(' ')} placeholder="Enter Password" required="required" defaultValue="" onChange={(e) => validatePassword(e.target.value)} />
                              <div className="clearfix"></div>
                              <span className={style.loginSignupError} >
                                 <span className={["field-validation-valid", style.customFontSizeSmall].join(' ')} style={{ fontSize: "15px" }}>{passwordErrorMessage}</span>
                              </span>
                           </div>


                        </div>
                        <p className={[" txt-que mb-6", style.txtQue, style.mb6].join(' ')}>
                           <a href="/#/forgot-password" style={{ cursor: "pointer" }}>
                              <span className={["lbl-txt", style.lblTxt].join(' ')} style={{ color: "#000000" }}>Forgot
                                 <span className={["blu", style.blu].join(' ')} > Password ?</span> </span>
                           </a>
                        </p>
                        <div className={["col-sm-12   pl-0  col-xs-12  btm-wrp", style.pl0].join(' ')}>
                           <button type="submit" className={["btn  btn-blue con-btn", style.btn, style.btnBlue, style.conBtn].join(' ')} name="LOGIN" disabled={isLoginBtnDisabled}> {loginBtnText} </button>
                           <span className={style.loginSignupError} >
                              <span className={["field-validation-valid", style.customFontSizeSmall].join(' ')} style={{ fontSize: "15px" }}>{formSubmissionErrorMessage}</span>
                           </span>
                           <div className="clearfix"></div>
                        </div></div>


                  </div>
               </form>
               </div>

               <div className={["col-xs-12 col-sm-offset-1 col-sm-9 pr-0 rtlogo-dv", style.pr0, style.rtlogoDv].join(' ')} style={{ top: "600px" }}>
                  <ul className={["bannerbtm-rt", style.bannerbtmRt].join(' ')}>
                     <li>
                        <a href="https://www.irs.gov/e-file-providers/tax-year-2024-94x-modernized-e-file-mef-providers" target="_blank">
                           <img src="https://prod-cdn.tax1099.com/Content/NewTemplate/Images/h-irs.png" alt="Tax1099 - Authorized IRS eFile Provider" />
                        </a>
                     </li>
                     <li>
                        <img src="https://prod-cdn.tax1099.com/Content/NewTemplate/Images/h-soc.png" alt="SSAE-16 Type II Certification" />
                     </li>
                     <li>
                        <img src="https://prod-cdn.tax1099.com/Content/NewTemplate/Images/h-ssa.png" alt="U.S Department of State" />

                     </li>
                     <li>
                        <img src="https://prod-cdn.tax1099.com/Content/NewTemplate/Images/h-cpa.png" alt="Tax1099 - Authorized IRS eFile Provider" />

                     </li>
                     <li>
                        <img src="https://prod-cdn.tax1099.com/Content/NewTemplate/Images/h-cep.png" alt="SSAE-16 Type II Certification" />

                     </li>
                  </ul>
               </div>
         </div>
   );
}