import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'reactstrap';
import style from './LoginPage.styles.css';
import './LoginPage.styles.css';
import img1 from './img/sign-logowht.png';
import img2 from './img/BG.png';
import './LoginPage.styles.css';

export default function LeftPage({ }) {
    return (
        <div className={["col-12 col-sm-6 plr-0 lf divxs bginside-div", style.plr0, style.divxs, style.bginsideDiv]} style={{ backgroundImage: `url(${img2})`}}>
            <div className="col-sm-10 col-sm-offset-1 col-xs-12">
                <div className="row">
                    <div className={["col-sm-12 logo-space ml-4", style.logoSpace, style.ml4].join(' ')}>
                        <img src={img1} alt="image" />
                    </div>
                    <div className={["col-sm-12 frxlg pl-0 divwrap", style.pl0, style.divwrap].join(' ')}>
                        <div className={["col-sm-12  mb-15   plr-0 pl-0 ml-4", style.mb15, style.plr0, style.pl0, style.ml4].join(' ')}>
                            <p className={["co-wt fs-30 mb-13", style.coWt, style.fs30, style.mb13].join(' ')}>Developer API Portal</p>
                            <div className={["col-sm-12 pl-0 plr-0 mt-10  db", style.pl0, style.plr0, style.mt10].join(' ')} style={{ display: "flex" }}>
                                <ul className={["topul", style.topul].join(' ')}>
                                    <li>Easy to Use APIs</li>
                                    <li>Sample Code in Multiple Languages</li>
                                    <li>Secure API Access</li>
                                    <li>Developer Support</li>
                                </ul>
                            </div>
                        </div>
                        <div className={["col-sm-12  mtb-middv   plr-0  ml-4", style.mtbMiddv, style.plr0, style.ml4].join(' ')}>
                            <p className={[" co-wt fs-30", style.coWt, style.fs30].join(' ')}> Award winning eFiling Platform</p>
                            <div className={["col-xs-12 col-sm-12 col-md-12 md-pf0 padding-right", style.mdPf0].join(' ')}>

                                <ul className={["nav nav-tabs bannerbtm", style.bannerbtm].join(' ')} style={{ borderBottom: "0" }}>

                                    <li>
                                        <span className={["smalltxt-bannerbtm", style.smalltxtBannerbtm].join(' ')}>
                                            Businesses file
                                        </span>
                                        <span className={["smalltxt-bannerbtm", style.smalltxtBannerbtm].join(' ')}>
                                            through us
                                        </span>
                                        <span className={["bigtxt-bannerbtm", style.bigtxtBannerbtm].join(' ')}>500K+ </span>
                                    </li>

                                    <li>
                                        <span className={["smalltxt-bannerbtm", style.smalltxtBannerbtm].join(' ')}>
                                            Income Reported
                                        </span>
                                        <span className={["smalltxt-bannerbtm", style.smalltxtBannerbtm].join(' ')}>
                                            to IRS in 2022 TY
                                        </span>
                                        <span className={["bigtxt-bannerbtm", style.bigtxtBannerbtm].join(' ')}>$413 Billion </span>
                                    </li>

                                    <li>
                                        <span className={["smalltxt-bannerbtm", style.smalltxtBannerbtm].join(' ')}>
                                            Transactions
                                        </span>
                                        <span className={["smalltxt-bannerbtm", style.smalltxtBannerbtm].join(' ')}>
                                            in 2022 TY
                                        </span>
                                        <span className={["bigtxt-bannerbtm", style.bigtxtBannerbtm].join(' ')}>30M+</span>

                                    </li>
                                    <li>
                                        <span className={["smalltxt-bannerbtm", style.smalltxtBannerbtm].join(' ')}>
                                            Rating
                                        </span>
                                        <span className={["smalltxt-bannerbtm", style.smalltxtBannerbtm].join(' ')}>
                                            Based on 2314 Reviews
                                        </span>
                                        <span className={["bigtxt-bannerbtm", style.bigtxtBannerbtm].join(' ')}>4.8/5</span>
                                        <span className={["smalltxt-bannerbtm", style.smalltxtBannerbtm].join(' ')}>
                                            <img src="https://prod-cdn.tax1099.com/Content/NewTemplate/Images/h-star.png" alt="Tax1099 - Authorized IRS eFile Provider" />

                                        </span>
                                    </li>
                                </ul>

                            </div>
                            <p className={["footertext co-wt", style.footertext, style.coWt].join(' ')}>
                                &copy; 2024 Tax1099.com All Rights Reserved | Powered by Zenwork, Inc.
                            </p>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}