import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'reactstrap';
import style from './AcceptInvitation.styles.css';
import Logo from '../../../assets/jars_logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { componentKey } from './AcceptInvitationSlice';
import { genRandomString } from './AcceptInvitationSaga';
import CommonJs from '../../../assets/js/common.js';
import queryString from 'query-string';
import validator from 'validator';
import axios from 'axios';

export default function AcceptInvitation({}) {

    var query_param = document.location.href.replace("/#/", "/").split("?");
    if(query_param.length > 1){
        query_param = query_param[1];
    }else{
        query_param = query_param[0];
    }

    let params = queryString.parse(query_param)
    var Email = (typeof params.Registration !== 'undefined') ? params.Registration : "";
    var Secret = (typeof params.Param !== 'undefined') ? params.Param : "";
    var Master = (typeof params.Master !== 'undefined') ? params.Master : "";

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [termsCondition, setTermsCondition] = useState(false);
    const [isInviteValid, setIsInviteValid] = useState(null);
    const [inviteValidMessage, setInviteValidMessage] = useState("");

    const [firstnameErrorMessage, setFirstnameErrorMessage] = useState('')
    const [lastnameErrorMessage, setLastnameErrorMessage] = useState('')
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('')
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('')
    const [termsConditionErrorMessage, setTermsConditionErrorMessage] = useState('')
    const[formSubmissionErrorMessage, setFormSubmissionErrorMessage] = useState('');

    const dispatch = useDispatch();
    useEffect(() => {        
        axios
        .post(process.env.API_URL + "/api/v1/developer/validateinvite", {"Email": Email, "Secret": Secret, "Master": parseInt(Master)}).then((res) => {
            setIsInviteValid(res.data.status);
            setInviteValidMessage(res.data.message);
        });


    }, []);
    const { randomString } = useSelector((state) => state[componentKey])
   

    const setPhoneNumber = (value) =>{
        setPhone(value);
    }
 
    const validateFirstname = (value) => {
        setFirstname(value);
        if ($.trim(value) != ""){
            setFirstnameErrorMessage('');
            return true;
        }
        setFirstnameErrorMessage('First Name is required.');
        return false;
    }

    const validateLastname = (value) => {
        setLastname(value);
        if ($.trim(value) != ""){
            setLastnameErrorMessage('');
            return true;
        }
        setLastnameErrorMessage('Last Name is required.');
        return false;
    }


    const validatePassword = (value) => {
        setPassword(value);
        if (validator.isStrongPassword(value, {
        minLength: 8, minLowercase: 1,
        minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setPasswordErrorMessage('');
            return true;
        }
        setPasswordErrorMessage('Your password must have 8 or more characters, upper and lowercase letters, at least one number, and at least one special character.');
        return false;
    }

    const validateConfirmPassword = (value) => {
        setConfirmPassword(value);
        if (value != "" && value == password && validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
            })) {
            setConfirmPasswordErrorMessage('');
            return true;
        }
        setConfirmPasswordErrorMessage('The Password and Confirmation Password must match and should not be empty.');
        return false;
    }

    const validateTermsCondition = (value) => {
        setConfirmPassword(value);
        if (value != "" && value == password && validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
            })) {
            setConfirmPasswordErrorMessage('');
            return true;
        }
        setConfirmPasswordErrorMessage('The Password and Confirmation Password must match and should not be empty.');
        return false;
    }

    const MndaChecked = (e) => {
        console.log(e.target.checked);
        setTermsCondition(e.target.checked);
        if(e.target.checked){
            setTermsConditionErrorMessage('');
            return true;
        }
        setTermsConditionErrorMessage('Please accept Terms and Condition.');
        return false;
    }

    const validateForm = (e) => {
        setFormSubmissionErrorMessage('');
        e.preventDefault();
        var status = validateFirstname(firstname);
        status = (validateLastname(lastname) && status);
        status = (validatePassword(password) && status);
        status = (validateConfirmPassword(confirmPassword) && status);
        if(!termsCondition){
            setTermsConditionErrorMessage('Please accept Terms and Condition.');
            status = false;
        }else{
            if(status){
                axios
                .post(process.env.API_URL + "/api/v1/developer/acceptinvite", {
                    Email: Email,
                    FirstName: firstname,
                    LastName: lastname,
                    Password: password,
                    ConfirmPassword: confirmPassword,
                    isMndaSigned: "1",
                    Secret: Secret,
                    Master: parseInt(Master),
                    Phone: phone
                }).then((res) => {
                    if(res.data.status){
                        document.location = res.data.redirectURL;
                    }else{
                        setFormSubmissionErrorMessage(res.data.message);
                    }
                }).catch((error) => {
                    setFormSubmissionErrorMessage(error.response.data.message);
                });
            }            
        }
    }

    
    return (
        <Container className={style.mainContainer} fluid>
            <div className="col-md-12 col-xs-12 ">
                <div className="offset-xl-2">
                    <div className="col-xl-8 col-md-12 col-xl-offset-2 main-content1 col-xs-12" id={style.mainContent1}>
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <div className=" col-md-12 col-xs-12">
                                    <h3 className={["text-center col-md-12 col-xs-12", style.btmFont].join(' ')}>Accept Invitation </h3>
                                    {
                                        (isInviteValid === null) ? <> <i className={["fa fa-spinner fa-spin", style.loadingSpinner].join(' ')}></i><span className={style.customFontSizeSmall}> Loading...</span></>
                                        : (isInviteValid == 1) ?
                                        <div id="divSetPassword" runat="server">
                                                        <div className="hr_login_signup_box"></div>
                                                        <form method="post" onSubmit={ (e) => validateForm(e)}>
                                                            <input name="__RequestVerificationToken" type="hidden" defaultValue="wz8IraGGiVgnlv21W47P3i_YRaBSVpt3byud-W-JMzMmzC9w-WYcoqhKZSsQCnPKcLYuNKMIRwmIiqDkUMr9zH2Ml2PgKxBc9WNFvUbwvtY1" />
                                                            <div className="row reg-formdiv">
                                                                <div className={["form-group col-xs-12 col-md-12", style.formGroup].join(' ')}>
                                                                    <label htmlFor="UserName" className={style.customFormLabels}>User Name</label>
                                                                    <input className={["form-control regtxtbox-bg", style.inputFormControl].join(' ')}  name="UserName" placeholder="you@company.com" type="email" defaultValue={Email} required readOnly={true} />
                                                                    <div className="clearfix"></div>
                                                                    <span className={style.loginSignupError} >
                                                                        <span className={["field-validation-valid", style.customFontSizeSmall].join(' ')} ></span>
                                                                    </span>
                                                                </div>
                                                                <div className={["form-group col-xs-12 col-md-12", style.formGroup].join(' ')}>
                                                                    <label htmlFor="FirstName" className={style.customFormLabels}>First Name <i className="text-danger">*</i>
                                                                    </label>
                                                                    <input className={["form-control regtxtbox-bg", style.inputFormControl].join(' ')} id="FirstName" name="FirstName" placeholder="First Name" type="text" defaultValue="" onChange={(e) => validateFirstname(e.target.value)} />
                                                                    <div className="clearfix"></div>
                                                                    <span className={style.loginSignupError} >
                                                                        <span className={["field-validation-valid", style.customFontSizeSmall].join(' ')} >{firstnameErrorMessage}</span>
                                                                    </span>
                                                                </div>
                                                                <div className={["form-group col-xs-12 col-md-12", style.formGroup].join(' ')}>
                                                                    <label htmlFor="LastName" className={style.customFormLabels}>Last Name <i className="text-danger">*</i>
                                                                    </label>
                                                                    <input className={["form-control regtxtbox-bg", style.inputFormControl].join(' ')} id="LastName" name="LastName" placeholder="Last Name" type="text" defaultValue="" onChange={(e) => validateLastname(e.target.value)} />
                                                                    <div className="clearfix"></div>
                                                                    <span className={style.loginSignupError} >
                                                                        <span className={["field-validation-valid", style.customFontSizeSmall].join(' ')}>{lastnameErrorMessage}</span>
                                                                    </span>
                                                                </div>
                                                                <div className={["form-group col-xs-12 col-md-12", style.formGroup].join(' ')}>
                                                                    <label htmlFor="Password" className={style.customFormLabels}>New Password <i className="text-danger">*</i>
                                                                    </label>
                                                                    <input className={["form-control regtxtbox-bg", style.inputFormControl].join(' ')} id="Password" name="Password" placeholder="Password" type="password" onChange={(e) => validatePassword(e.target.value)} />
                                                                    <div className="clearfix"></div>
                                                                    <span className={style.loginSignupError} >
                                                                        <span className={["field-validation-valid", style.customFontSizeSmall].join(' ')} >{passwordErrorMessage}</span>
                                                                    </span>
                                                                </div>
                                                                <div className={["form-group col-xs-12 col-md-12", style.formGroup].join(' ')}>
                                                                    <label htmlFor="ConfirmPassword" className={style.customFormLabels}>Confirm Password <i className="text-danger">*</i>
                                                                    </label>
                                                                    <input className={["form-control regtxtbox-bg", style.inputFormControl].join(' ')} id="ConfirmPassword" name="ConfirmPassword"onChange={(e) => validateConfirmPassword(e.target.value)}  placeholder="Confirm Password" type="password" />
                                                                    <div className="clearfix"></div>
                                                                    <span className={style.loginSignupError} >
                                                                        <span className={["field-validation-valid", style.customFontSizeSmall].join(' ')} >{confirmPasswordErrorMessage}</span>
                                                                    </span>
                                                                </div>
                                                                <div className={["form-group col-xs-12 col-md-12", style.formGroup].join(' ')}>
                                                                    <label htmlFor="PhoneNumber" className={style.customFormLabels}>Contact Number
                                                                    </label>
                                                                    <input className={["form-control regtxtbox-bg", style.inputFormControl].join(' ')} id="PhoneNumber" maxLength="20" name="PhoneNumber" placeholder="Contact Number" type="text" defaultValue="" onChange={(e) => setPhoneNumber(e.target.value)} />
                                                                </div>
                                                                <div className={["form-group col-xs-12 col-md-12", style.formGroup].join(' ')}>
                                                                    <input type="checkbox" id="_id_IsMndaSigned" name="IsMndaSigned" defaultValue="1" onChange={(e) => MndaChecked(e)} />
                                                                    <label htmlFor="_id_IsMndaSigned" className={style.idIsMndaSigned}>
                                                                        <i className="text-danger">*</i>&nbsp;By clicking, you are confirming that you have read, understood and agree to the <a className={style.externalLink} href="https://www.1099cloud.com/saas-terms-and-conditions" target="_blank">terms and conditions <i className={["fa fa-external-link", style.customFontSizeSmall, style.defaultHide].join(' ')} aria-hidden="true"></i>
                                                                        </a>
                                                                    </label>
                                                                    <div className="clearfix"></div>
                                                                    <span className={style.loginSignupError} >
                                                                        <span className={["field-validation-valid", style.customFontSizeSmall].join(' ')}>      {termsConditionErrorMessage}</span>
                                                                    </span>
                                                                </div>
                                                                <div className={["col-xs-12 col-md-offset-1 col-md-12 ", style.acceptInvitationButtonCntnr].join(' ')}>
                                                                    <span className={style.loginSignupError} >
                                                                        <span className={["field-validation-valid", style.customFontSizeSmall].join(' ')} >{formSubmissionErrorMessage}</span>
                                                                    </span>
                                                                    <div className="clearfix"></div>
                                                                    <button type="submit" className={["btn btn-success btn-sm", style.loginbtn].join(' ')}>Accept Invitation</button>
                                                                </div>
                                                                <div className={["form-group col-xs-12 col-md-12", style.formGroup, style.acceptInvitationButtonCntnr].join(' ')}>
                                                                    <i className={style.customFontSizeSmall}>
                                                                        <i className="text-danger">*</i> Mandatory fields </i>
                                                                </div>
                                                            </div>
                                                        </form>
                                        </div>
                                        :
                                        <div id="divSetPassword" runat="server"><label style={{color: "#135db1"}}><b>{inviteValidMessage}</b></label></div>
                                    }
                                </div>
                            </div>
                            <div className={["col-md-6 col-xs-12", style.hideThisOnSmallDevice].join(' ')}>
                                <div className={style.img001}>
                                    <img src="https://www.1099cloud.com/Content/NewTemplate/Images/MemberUserInvitation.PNG" className={["img-responsive hidden-xs", style.invimg].join(' ') }/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <script src={CommonJs} ></script>
        </Container>
    );

}