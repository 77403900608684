import React, { useEffect } from "react";
import { Container, Row, Col } from 'reactstrap';
import styles from '../acceptinvitation/AcceptInvitation.styles.css';
export default function Header({}) {
	return (
		<nav className={["navbar navbar-default", styles.navbarFixedTop].join(' ')}>
			<div className={["navContainer", styles.navContainer].join(' ')}>
				<div className={["navbar-header", styles.navbarHeader].join(' ')}>
					<a className="navbar-brand" id="logoURL" href="https://www.tax1099.com">
						<img id={styles.logoimgUrl} src="https://www.1099cloud.com/Content/NewTemplate/Images/Tax1099.svg" alt="Tax1099 - IRS Approved E-file Provider" />
					</a>
				</div>
				<nav className="collapse navbar-collapse show" role="navigation">
					<ul className="nav navbar-nav navbar-right menu-one" style={{paddingRight: "0px", position: "fixed",
    top: "24px", right: "45px", display:"block !important"}}>
						<li className="sec-li"><a href="/#/" style={{color: "#878787", fontSize: "16px", left: "0.4em !important", marginTop: "2px", float: "right"}}>Login </a></li>
					</ul>
				</nav>
			</div>
		</nav>
    );
}